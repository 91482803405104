<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <roles-permissions-content />
    </v-container>
  </div>
</template>

<script>
import RolesPermissionsContent from "@/modules/users/views/components/RolesPermissionsContent.vue";

export default {
  components: {
    RolesPermissionsContent,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Team",
          disabled: false,
          exact: true,
          to: { name: "module-team" },
        },
        {
          text: "Roles & Permissions",
          disabled: true,
        },
      ],
    };
  },
};
</script>
